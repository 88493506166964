const create = (baseModule, opts, sdkApi) => {
  return {
    /**
     * 搜索 查询IMO MMSI 拉起左侧船舶 
     * {@link https://miniapp.hifleet.com/miniappdemo/search.html|示例}
     * @param {string} keyword 搜索 查询IMO MMSI 拉起左侧船舶
     * @example
     * app.hfSearchBy(keyword);
     */
    hfSearchBy(hfParams) {
      // console.log("hfSearchBy",hfParams)
      top.postMessage({
        hfMethodName: 'hfSearchBy',
        hfParams
      },"*");
    },
    /**
     * 打开 PSC\保险\证书\检验报告页面
     * {@link https://miniapp.hifleet.com/miniappdemo/search.html|示例}
     * @param {Object} options 
     * @param {string} options.keyword 搜索 查询IMO MMSI 拉起左侧船舶
     * @param {string} options.entry openPSC openInsurance openCert openReport
     * @example
     * let options ={keyword:'123456',entry:'openPSC'}
     * app.hfOpen(options);
     */
    hfOpen(hfParams) {
      // console.log("hfOpen",hfParams)
      top.postMessage({
        hfMethodName: 'hfOpen',
        hfParams
      },"*");
    }
  }//end return;
}//end create

export default {
  create,
}