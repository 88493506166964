import searchSDK from "./sdk/search"
import frameSDK from "./sdk/frame"
/**
 * 创建sdk实例
 * @example
 * let frameId = "hifleet_iframe_id"; // 如果以iframe嵌入
 * let app = hifleet.createMiniApp({frameId})//initialize the miniApp SDK
 */
export function createMiniApp(_) {
  let exportSearchSDK = searchSDK.create()
  let exportFrameSDK = frameSDK.create(_)
  let exportSdk = {
    ...exportSearchSDK,
    ...exportFrameSDK
  }
  return {
    ...exportSdk,
  }
}