const create = (baseModule, opts, sdkApi) => {
  const {
    frameId
  } = baseModule || {}
  let sub_iframe;
  let sub_iframe_contentWindow;
  if (frameId) {
    sub_iframe = document.getElementById(frameId);
    sub_iframe_contentWindow = sub_iframe.contentWindow
  }

  return {
    /**
     * 搜索 查询IMO MMSI 拉起左侧船舶 
     * {@link https://miniapp.hifleet.com/miniappdemo/searchframe.html|示例}
     * @param {string} keyword 搜索 查询IMO MMSI 拉起左侧船舶
     * @example
     * app.hfIFrameSearchBy(keyword);
     */
    hfIFrameSearchBy(hfParams) {
      // console.log("hfSearchBy",hfParams)
      if (!sub_iframe_contentWindow) {
        console.log("no hifleet frame");
        return;
      }
      sub_iframe_contentWindow.postMessage({
        hfMethodName: 'hfSearchBy',
        hfParams
      }, "*");
    },
    /**
     * 打开 PSC\保险\证书\检验报告页面
     * {@link https://miniapp.hifleet.com/miniappdemo/searchframe.html|示例}
     * @param {Object} options 
     * @param {string} options.keyword 搜索 查询IMO MMSI 拉起左侧船舶
     * @param {string} options.entry openPSC openInsurance openCert openReport
     * @example
     * let options ={keyword:'123456',entry:'openPSC'}
     * app.hfIFrameOpen(options);
     */
    hfIFrameOpen(hfParams) {
      // console.log("hfOpen",hfParams)
      if (!sub_iframe_contentWindow) {
        console.log("no hifleet frame");
        return;
      }
      sub_iframe_contentWindow.postMessage({
        hfMethodName: 'hfOpen',
        hfParams
      }, "*");
    }
  } //end return;
} //end create

export default {
  create,
}